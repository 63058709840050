<template>
  <div class="mb-5">
    <div class="mb-8 flex justify-between items-center">
      <div class="text-lg font-bold">Branch</div>
      <button class="btn btn-blue btn-sm" @click="$refs.branchModal.open()">
        + Add New Branch
      </button>
    </div>

    <template v-if="getFormError(branchResource)">
      <div class="alert alert-red-soft mb-10">
        <span class="alert-icon">!</span>
        <span>{{ getFormError(branchResource) }}</span>
      </div>
    </template>

    <datatable
      :data="branches"
      :columns="branchResource.columns"
      :actions="branchResource.actions"
      ref="table"
    />

    <modal className="w-11/12 sm:w-443px" ref="branchModal">
      <h1 class="text-2xl text-left font-bold mb-8">
        Add Branch
      </h1>
      <form @submit.prevent="addBranch">
        <template v-if="getFormError(branchForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(branchForm) }}</span>
          </div>
        </template>

        <form-group
          name="name"
          :form="branchForm"
          v-model="branchForm.data.name.value"
        >
          Name
        </form-group>

        <div class="mt-3">
          <form-group
            type="select"
            :options="regionOptions"
            name="region_id"
            :form="branchForm"
            v-model="branchForm.data.region_id.value"
          >
            Region
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="textarea"
            name="address"
            :form="branchForm"
            v-model="branchForm.data.address.value"
          >
            Address
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="phone"
            name="phone"
            :form="branchForm"
            v-model="branchForm.data.phone.value"
          >
            Phone Number
          </form-group>
        </div>

        <form-group
          name="account_no"
          :form="branchForm"
          v-model="branchForm.data.account_no.value"
        >
          Account No
        </form-group>

        <div class="mt-3">
          <form-group
            type="select"
            :options="bankOptions"
            name="bank_name"
            :form="branchForm"
            v-model="branchForm.data.bank_name.value"
          >
            Bank Name
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="select"
            :options="states"
            name="state"
            :form="branchForm"
            v-model="branchForm.data.state.value"
          >
            State
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="select"
            :options="lgas"
            name="lga"
            :form="branchForm"
            v-model="branchForm.data.lga.value"
          >
            LGA
          </form-group>
        </div>

        <div class="flex justify-center">
          <button
            class="btn w-full btn-blue font-normal"
            type="submit"
            :disabled="branchForm.loading"
          >
            Submit
          </button>
        </div>
      </form>
    </modal>

    <update-branch
      v-if="branchResource.data"
      :branch="branchResource.data"
      :regions="regions"
      :key="componentKey"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    branches: {
      type: Array,
      default: () => []
    },
    regions: {
      type: Array,
      default: () => []
    },
    profileId: {
      type: String,
      default: ''
    }
  },
  components: {
    UpdateBranch: () => import('./components/UpdateBranch')
  },
  data() {
    return {
      componentKey: 0,
      branchForm: this.$options.basicForm([
        { name: 'merchant_id', value: this.profileId },
        { name: 'region_id', rules: 'required|aplpha' },
        { name: 'name', rules: 'required' },
        { name: 'address', rules: 'required' },
        { name: 'state', rules: 'nullable' },
        { name: 'lga', rules: 'nullable' },
        { name: 'phone', rules: 'required|phone' },
        { name: 'account_no', rules: 'required|number' },
        { name: 'bank_name', rules: 'required|aplpha' }
      ]),
      branchResource: this.$options.resource(null, {
        columns: [
          {
            name: 'id',
            th: 'Branch ID'
          },
          {
            name: 'name',
            th: 'Name'
          },
          {
            name: 'region',
            th: 'Region',
            render: branch => branch?.region?.name || 'N/A'
          },
          {
            name: 'account_no',
            th: 'Account No'
          },
          {
            name: 'bank_name',
            th: 'Bank Name',
            render: (branch, bank_name) =>
              this.banks?.find(bank => bank.code === bank_name)?.name
          },
          {
            name: 'address',
            th: 'Address'
          },
          {
            name: 'state',
            th: 'State'
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (loan, created_at) =>
              this.$options.filters.dateFormat(created_at, 'D, dd M Y')
          }
        ],
        actions: [
          {
            text: 'edit',
            class: 'border-blue-500 text-blue-500 rounded-sm px-4 py-2',
            action: this.editBranch
          },
          {
            text: 'delete',
            class: 'border-red-500 text-red-500 rounded-sm px-4 py-2',
            action: this.deleteBranch
          }
        ]
      })
    };
  },
  watch: {
    'branchForm.data.state.value'() {
      this.branchForm.data.lga.value = '';
    }
  },
  computed: {
    ...mapState(['states', 'banks']),
    regionOptions() {
      return this.regions.length
        ? this.regions?.map(region => ({
            title: region.name,
            value: region.id
          }))
        : [];
    },
    bankOptions() {
      return this.banks.length
        ? this.banks.map(bank => ({
            title: bank.name,
            value: bank.code
          }))
        : [];
    },
    states() {
      return this.$store.state.states?.map(state => state.name);
    },
    lgas() {
      return (
        this.$store.state.states
          ?.find(state => state.name === this.branchForm.data.state.value)
          ?.locals.map(lga => lga.name) || []
      );
    }
  },
  methods: {
    async addBranch() {
      if (!this.validateForm(this.branchForm)) return false;

      this.branchForm.loading = true;
      this.branchForm.error = false;

      await this.sendRequest('admin.merchants.branch.add', {
        data: this.getFormData(this.branchForm),
        success: () => {
          this.resetForm(this.branchForm);
          this.$refs.branchModal.close();
          this.$emit('success');
        },
        error: error => (this.branchForm.error = error)
      });
      this.branchForm.loading = false;
    },
    editBranch(branch) {
      this.branchResource.data = { ...branch };
      this.$nextTick(() => {
        this.componentKey += 1;
      });
    },
    async deleteBranch({ id }) {
      await this.sendRequest('admin.merchants.branch.destroy', id, {
        success: () => this.$emit('success'),
        error: error => (this.branchResource.error = error)
      });
    }
  }
};
</script>
